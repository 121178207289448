<template>
    <div class="Profile-notifications">
        <ul class="nav nav-tabs notifications-tabs">
            <li class="nav-item">
                <a class="nav-link notifications-tabs__title" 
                    @click.prevent="getNewNotifications"
                    :class="{ active: isActive('new') }" href="#new">
                    {{$t("profile.notifications.new-tab")}} ({{ newNotifications.length }})
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link notifications-tabs__title" 
                    @click.prevent="getOldNotifications"
                    :class="{ active: isActive('old') }" href="#old">
                    {{$t("profile.notifications.read-tab")}} ({{ oldNotifications.length }})
                </a>
            </li>
        </ul>
        <div class="tab-content notifications-tab-content">
            <div class="tab-pane fade" :class="{ 'active show': isActive('new') }" id="new">
                <div class="notifications-tab-content__cards">
                    <div class="notifications-card" v-for="notification in newNotifications">
                        <div class="notifications-card__left">
                            <div class="notifications-card__header">
                                <img src="/images/profile/message-icon.svg" width="32" height="21" alt="">
                                <div class="notifications-card__title">
                                    {{ notification.data.message }}
                                </div>
                            </div>
                            <div class="notifications-card__btns">
                                <button class="notifications-card__btn-open" @click="readNotification(notification.id)" >
                                    {{$t("profile.notifications.btn-open")}}
                                </button>
<!--                                <button class="notifications-card__btn-clear">-->
<!--                                    {{$t("profile.notifications.btn-clear")}}-->
<!--                                </button>-->
                            </div>
                        </div>
                        <div class="notifications-card__right">
                            <div class="notifications-card__desc-block">
                                <div class="notifications-card__desc">
                                    {{ notification.data.data }}
                                </div>
                            </div>
                            <div class="notifications-card__date">
                                {{ moment(notification.created).format('D.MM.YY H:mm') }}
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-if="rowsTests > perPageTests"
                    v-model="currentPageTests"
                    :total-rows="rowsTests"
                    :per-page="perPageTests"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
            </div>

            <div class="tab-pane fade" :class="{ 'active show': isActive('old') }" id="old">
                <div class="notifications-tab-content__cards">
                    <div class="notifications-card" v-for="notification in oldNotifications">
                        <div class="notifications-card__left">
                            <div class="notifications-card__header">
                                <img src="/images/profile/message-icon-grey.svg" width="32" height="21" alt="">
                                <div class="notifications-card__title">
                                    {{ notification.data.message }}
                                </div>
                            </div>
                            <div class="notifications-card__btns">
                                <button class="notifications-card__btn-open notifications-card__btn-open--purple" @click="readNotification(notification.id)">
                                    {{$t("profile.notifications.btn-open")}}
                                </button>
<!--                                <button class="notifications-card__btn-clear">-->
<!--                                    {{$t("profile.notifications.btn-clear")}}-->
<!--                                </button>-->
                            </div>
                        </div>
                        <div class="notifications-card__right">
                            <div class="notifications-card__desc-block">
                                <div class="notifications-card__desc">
                                    {{ notification.data.data }}
                                </div>
                            </div>
                            <div class="notifications-card__date">
                                {{ moment(notification.created).format('D.MM.YY H:mm') }}
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-if="rowsResults > perPageResults"
                    v-model="currentPageResults"
                    :total-rows="rowsResults"
                    :per-page="perPageResults"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
            </div>
        </div>
        <b-modal id="modal-notification" hide-footer hide-header centered class="modal-notification">
            <b-button class="modal-notification__btn" block @click="$bvModal.hide('modal-notification')">
                <img src="/images/profile/close-icon.svg" width="8" height="8" alt="">
            </b-button>
            <div class="modal-notification__title" v-if="currentNotification.data">
                {{ currentNotification.data.data }}
            </div>
            <div class="modal-notification__desc" v-if="currentNotification.data">
                {{ currentNotification.data.data }}
            </div>
            <div class="modal-notification__date" v-if="currentNotification.created_at">
                {{ moment(currentNotification.created_at).format('D.MM.YY H:mm') }}
            </div>
        </b-modal>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                newNotifications: [],
                oldNotifications: [],
                currentNotification: {},

                activeItem: 'new',
                isHiddennew: true,
                isHiddenold: true,
                currentPageTests: 1,
                rowsTests: 1,
                perPageTests: 1,
                currentPageResults: 1,
                rowsResults: 1,
                perPageResults: 1,
                allNotifications: false,
                loading: false
            }
        },
        mounted() {
            this.getNewNotifications()
        },
        methods: {
            getNewNotifications() {
                this.activeItem = 'new'
                this.$http.get(`${window.API_ROOT}/api/notifications?type=new`)
                    .then((res) => {
                        this.newNotifications = res.body.data
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            getOldNotifications() {
                this.activeItem = 'old'
                this.$http.get(`${window.API_ROOT}/api/notifications?type=old`)
                    .then((res) => {
                        this.oldNotifications = res.body.data
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            readNotification(id) {
                this.$bvModal.show('modal-notification')
                this.$http.get(`${window.API_ROOT}/api/notifications/read/${id}`)
                    .then((res) => {
                        this.currentNotification = res.body.notification
                        this.loading = false
                    })
                    .catch((e) => {
                        this.loading = false
                    })

            },
            isActive (menuItem) {
                return this.activeItem === menuItem
            },
            setActive (menuItem) {
                this.activeItem = menuItem
            },
        },
    }
</script>
<style lang="less" scoped>
.Profile-notifications {
    max-width: 100%;
}
.notifications-tabs {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 34px;
}
.nav-tabs.notifications-tabs {
    border-bottom: none;
}
.notifications-tabs__title {
    width: 218px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.notifications-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.notifications-tabs__title:hover {
    border: 1px solid #473F95;
}
.notifications-tabs .nav-item:first-child .notifications-tabs__title {
    border-radius: 5px 0px 0px 5px;
} 
.notifications-tabs .nav-item:last-child .notifications-tabs__title {
    border-radius: 0px 5px 5px 0px;
} 
.notifications-tab-content__cards .notifications-card {
    margin: 0 auto 16px auto;
}
.notifications-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 100%;
    padding: 32px;

    font-family: Roboto;
    max-width: 100%;
}
.notifications-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 10px;
    margin-left: 16px;
    margin-right: 16px;
}
.notifications-card__desc {
    color: #473F95;
    font-size: 12px;
    line-height: 14px;
}
.notifications-card__btn-open {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
    padding: 7px 24px;
    min-width: 114px;
    background: #D23168;
    border-radius: 5px;
    border: none;
    margin-right: 11px;
    max-height: 33px;
}
.notifications-card__btn-open--purple {
    background: #473F95;
}
.notifications-card__btn-clear {
    color: #473F95;
    font-size: 14px;
    line-height: 17px;
    padding: 7px 24px;
    min-width: 114px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #473F95;
    max-height: 33px;
}

.Profile-notifications .pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 28px;
}
.notifications-card__date {
    color: #473F95;
    font-size: 12px;
    line-height: 14px;
    margin-top: 17px;
    text-align: end;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item.active .page-link {
    z-index: 0;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
.notifications-card__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.notifications-card__btns {
    display: flex;
    flex-direction: row;
}
.notifications-card__left,
.notifications-card__right {
    display: flex;
    justify-content: space-between;
}
.modal-notification__btn {
    background: #473F95;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
    float: right;
}
.modal-notification__title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #473F95;
    padding-bottom: 20px;
    padding-top: 4px;
    max-width: 94%;
}
.modal-notification__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #473F95;
    padding: 0 10px 20px;
}
.modal-notification__desc-details {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #D23168;
}
.modal-notification__date {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #473F95;
    padding-top: 60px;
    float: right;
}
.delete-block {
    display: none;
}
/deep/ .el-checkbox {
    z-index: 0;
}
/deep/ .el-checkbox, .el-checkbox__input {
    display: flex;
    flex-direction: column;
}
/deep/ .el-checkbox__label {
    padding-left: 0;
}
/deep/ .el-checkbox__inner {
    border: 1px solid #473F95;
    border-radius: 100%;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner, 
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__label {
    color: #473F95;
    font-size: 10px;
    font-family: Roboto;
    line-height: 12px;
    padding-top: 3px;
}
/deep/ .el-checkbox, .el-checkbox__input {
    margin-bottom: 0;
}
.delete-block__amount {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #D23168;
    margin-left: 24px;
}
.delete-block__btn {
    border: none;
    background-color: transparent;
}
@media (max-width: 991px) {
    .notifications-tabs__title {
       width: auto;
       padding: 14px;
   }
   .notifications-tabs {
       margin-bottom: 30px;
   }
   .notifications-tabs .nav-item:first-child .notifications-tabs__title {
        border-radius: 12px;    
        margin-right: 12px;
        padding: 14px 32px;
   }
   .notifications-tabs .nav-item:last-child .notifications-tabs__title {
        border-radius: 12px;    
        padding: 14px 16px;
   }
   .notifications-card {
       flex-direction: column;
       padding: 20px;
   }
   .notifications-card__header {
       flex-direction: column-reverse;
   }
   .notifications-card__header img {
       width: 75px;
       height: 50px;
   }
   .notifications-card__title {
       text-align: center;
       padding-bottom: 32px;
   }
   .notifications-card__btns {
       display: none;
   }
   .notifications-card__date {
       text-align: center;
       margin-top: 28px;
       margin-bottom: 16px;
   }
   .notifications-card__left {
       justify-content: center;
   }
   .notifications-card__right {
       flex-direction: column-reverse;
   }
   .notifications-card__desc {
       text-align: center;
   }
   .Profile-notifications .pagination {
       padding-top: 14px;
       margin-bottom: 0;
   }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
   }
   .delete-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        padding: 8px;
        margin-bottom: 20px;
        max-width: 50%;
    }

}
@media (max-width: 575px) {
   .delete-block {
        max-width: 100%;
    }
}
</style>